body.no-padding-top_reset-senha {
    padding-top: 0 !important;
}

.form-container_reset-senha {
    display: flex;
    justify-content: center;
    align-items: center;
     height: 98vh;/* MOTIVO DO PROBLEMA */
    background-color: #3881ca;
    font-family: Arial, sans-serif;
}

.form-container_reset-senha 
form {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.form-container_reset-senha 
form label {
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    color: #666;
    margin-bottom: 15px;
}

.form-container_reset-senha 
form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.9em;
    margin-top: 5px;
}

.form-container_reset-senha form 
.input-submit_reset-senha {
    background-color: #3881ca;
    color: white;
    border: none;
    padding: 15px;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
    
}

.form-container_reset-senha form 
.input-submit_reset-senha:hover {
    background-color: #286f9e;
}

.submit-container {
    display: flex;
    justify-content: center;
    
}

.alerta {
    width: 300px;
    height: 200px;
    padding: 20px;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #ffffff;
    border-radius: 5px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.alerta-icon {
    width: 50px;
    height: 50px;
}

.alerta-texto {
    margin-top: 20px;
    color: #000000;
    font-size: 16px;
}


.alerta-sucesso {
    background-color: #4CAF50;
}

.alerta-erro {
    background-color: #d88a31;
}

