  /* body {
    padding-top: 65px; 
  }  */
   

  .container-input {
    position: relative;
    display: inline-block;
  }
  
  .search-icon {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 40px;
    background-color: #4152b3;
    border-radius: 4px 0 0 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  .search-icon::before {
    content: "";
    position: absolute;
    top: -12px;
    bottom: -12px;
    left: -2px;
    right: -5px;
  }
  
  .lgvisualizer {
    color: #fff;
  }
  
  .search-input {
    font-size: 15px;
    padding-left: 45px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    transition: width 0.3s ease;
  }
  

  .styled-suggestions {
    position: absolute;
    width: 100%;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    top: 100%;
    z-index: 1;
    color: black;
  }
  
  .styled-suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .styled-suggestion-item:hover {
    background-color: #eee;
  }
  

/*   
  .search-container {
    position: relative;
  }
  
  .search-input {
    width: 100%;
    padding: 3,5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .styled-suggestions {
    border: 1px solid #ddd;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fafafa;
    z-index: 1;
    box-shadow: 0 6px 12px rgba(167, 107, 107, 0.1);
  }
  
  .styled-suggestion-item {
    padding: 12px;
    cursor: pointer;
    color: #333;
    border-bottom: 1px solid #eee;
  }
  
  .styled-suggestion-item:last-child {
    border-bottom: none;
  }
  
  .styled-suggestion-item:hover {
    background-color: #eee;
  }
   */

 


  


.navbar {
    position: fixed;
    top: 0;
    width: 100%;  /* Tamanho Navbar*/
    z-index: 8888; 
    background-color: #4152b3;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    color: white;
    border-radius: 0%;
}

.modal{
  z-index: 9999; 
}


.navbar-left, .navbar-right {
    display: flex;
    align-items: center;
}

.nav-items {
    
  display: flex;
  align-items: center;
  justify-content: center; 
  text-align: center; 
    width: 30px;
    height: 30px;
}


.navbar-left > div, .navbar-right > div {
    margin-right: 20px;
}

.fas.fa-lg {
    font-size: 1.3em;
}

.settings a {
    color: white;
}

.navbar-left {
    justify-content: flex-start;
}

.navbar-right {
    justify-content: flex-end;
    flex-grow: 1;
    padding-right: 20px;
}

.notifications-dropdown {
    position: absolute;
    right: 13rem; 
    top: 60px;
    background-color: white;
    color: #36459b;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 24px;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transform: scale(0); 
    transform-origin: top right; 
    transition: all 0.3s ease-in-out;
    z-index: 9999;
}

.notifications-dropdown.show {
    max-height: 200px;
    opacity: 1;
    transform: scale(1); 
}



  .notification {
    padding: 10px 0;
    border-bottom: 1px solid #36459b;
  }
  
  .notification:last-child {
    border-bottom: none;
  }
  

  .ripple {
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  
  .ripple::after {
    content: ""; 
    display: block;
    position: absolute;
    width: 100%;
    height: 100%; 
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #87ceeb 100%, transparent 100.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(0, 0);
    opacity: 1;
    transition: transform 0.5s, opacity 0.5s;
    border-radius: 50%;
  }
  
  .ripple:active::after {
    transform: scale(2, 2);
    opacity: 0;
    transition: 0s;
  }
  
  
  
.user-dropdown {
    position: absolute;
    right: 10rem; 
    top: 60px;
    background-color: white;
    color: #36459b;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 24px;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transform: scale(0); 
    transform-origin: top right; 
    transition: all 0.3s ease-in-out;
    z-index: 9999;
}

.user-dropdown.show {
    max-height: 200px; 
    opacity: 1;
    transform: scale(1); 
}



  .user-not {
    padding: 10px 0;
    border-bottom: 1px solid #36459b;
  }
  
  .user-not:last-child {
    border-bottom: none;
  }


  .logout-button {
    background-color: #db1f12;
    color: white;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    margin-top: 10px;
    height: 3.0rem;
}

.logout-button:hover {
    background-color: #283593;
}
