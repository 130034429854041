

  
/* Spinner */



.loadingContainer {
  position: absolute;
  top: 19500%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-top-color: #000;
  animation: spin 1s ease-in-out infinite;
}

.loadingText {
  margin-left: 10px;
  font-size: 24px; 
  
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.topTable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 2%;

}

  /* Top Table */
  

  
  
  .topTable .btn-div {
    display: flex;
    gap: 10px;
  }
  
  .topTable .btn-div button {
    padding: 5px 12px;
    border: 1px solid #36459b;
    border-radius: 3px;
    background-color: transparent;
    color: #36459b;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .topTable .btn-div button:hover {
    background-color: #36459b;
    color: white;
  }
  
  .table-select{
    width: 200px;
    height: 40px;

  }

  /* css lancamentos */

  .topTableLancamentos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 2%;
    flex-direction: column;
  }

  .topTableLancamentos .btn-div {
    display: flex;
    gap: 10px;
  }
  
  .topTableLancamentos .btn-div button {
    padding: 5px 12px;
    border: 1px solid #36459b;
    border-radius: 3px;
    background-color: transparent;
    color: #36459b;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .topTableLancamentos .btn-div button:hover {
    background-color: #36459b;
    color: white;
  }


  .input-searchSaldoEsp {
    width: 200px;
    height: 40px; 
  }
  .btn-input-div {
    display: flex;
    justify-content: space-between;
    gap: 80px;
    margin-bottom: 20px;
  }

  .campoFiltro {
    width: 70%;
    float: left;
    background-color: #f1f1f1;
    height: 300px; /* Altura adicionada */
    padding: 20px;
    padding-top: 50px;

    
  }
  
  .campoData {
    width: 30%;
    float: left;
    background-color: #f1f1f1;
    height: 300px; /* Altura adicionada */
    padding: 5px;
  }
  

  .divFiltros{
    width: 85%;
    border-radius: 10px;
  }

  .tableSaldo {
    width: 85%;
    margin: 25px 0;
    font-size: 18px;
    text-align: left;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.1);
  }
  
  .tableSaldo table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableSaldo th,
  .tableSaldo td {
    padding: 12px 15px;
    border: 1px solid transparent;
    text-align: center;  /* Centraliza o texto */
    background-color: rgba(241, 240, 240, 0.938);
  }
  
  
  .tableSaldo th {
    background-color: #235a94;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  
  .tableSaldo tr {
    border-bottom: none;
    color: #333;
  }
  
  .tableSaldo tr:last-of-type {
    border-bottom: none;
  }
  
  
  
  
  
  .tituloFiltro1,
.tituloFiltro2,
.tituloFiltro3 {
  height: 33.33%;
  padding-top: 45px;
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  border: none;
  margin: 0;
}


  .filterButtonSaldo {
    margin-left: 10px;
    background-color: blue;
    height: 30px;
    color: white;
    padding: 5px 10px;
  }
  

  
  
  /* fim */
  
  .input-search {
    width: 200px;
    height: 40px;
  }



  .thTable {
    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
  }
  
  
  .sort-indicator {
   
    color: #36459b; 
    margin-left: 10px; 
  }

  /* Bottm table */
  .bottomTable {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .bottomTable .pagination-div {
    display: flex;
    gap: 10px;
  }
  
  .bottomTable .pagination-div .btnNext, .btnGoToPage {
    width: 45px;
    padding: 8px 16px;
    border: 1px solid #36459b;
    background-color: transparent;
    border-radius: 3px;
    color: #36459b;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}

  
  .bottomTable .pagination-div  button:hover {
    background-color: #36459b;
    color: white;
  }

  .bottomTable .pagination-div button.active {
    background-color: #36459b;
    color: white;
  }


  .btnPagination, .btnPage {
    padding: 8px 16px;
    border: 1px solid #36459b;
    background-color: transparent;
    border-radius: 3px;
    color: #36459b;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btnPagination:hover{
    background-color: #36459b;
    color: white;
  }

  
  .btnPage:hover{
    background-color: #36459b;
    color: white;
  }

  .currentPage{
    padding: 8px 16px;
    border: 1px solid #36459b;
    background-color: #36459b;
    border-radius: 3px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  #specificTable .thTable:nth-child(1) {
    width: 2%;
  }
  
  #specificTable .thTable:nth-child(2),
  #specificTable .thTable:nth-child(3) {
    width: 48%;
  }
  
  #specificTable .thTable:nth-child(4) {
    width: 2%;
  }
  

  #specificTable2 .thTable:nth-child(1) {
    width: 2%;
  }
  
  #specificTable2 .thTable:nth-child(2)
   {
    width: 88%;
  }
  
  #specificTable2 .thTable:nth-child(4) {
    width: 2%;
  }
