body.no-padding-top_reset-senha {
    padding-top: 0 !important;
}

.form-container_reset-senha {
    display: flex;
    justify-content: center;
    align-items: center;
     height: 98vh;/* MOTIVO DO PROBLEMA */
    background-color: #3881ca;
    font-family: Arial, sans-serif;
}

.form-container_reset-senha 
form {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.form-container_reset-senha 
form label {
    display: flex;
    flex-direction: column;
    font-size: 0.9em;
    color: #666;
    margin-bottom: 15px;
}

.form-container_reset-senha 
form input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 0.9em;
    margin-top: 5px;
}

.form-container_reset-senha form 
.input-submit_reset-senha {
    background-color: #3881ca;
    color: white;
    border: none;
    padding: 15px;
    margin-top: 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
    
}

.form-container_reset-senha form 
.input-submit_reset-senha:hover {
    background-color: #286f9e;
}

.submit-container {
    display: flex;
    justify-content: center;
    
}



.lds-ring {
    display: inline-block;
    position: relative;
    width: 30px;
    height: 30px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    margin: 3px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  
#btnResetSenha {
    margin-top: 1rem;
    padding: 0.4rem 1.3rem;
    font-size: 1.2rem;
    height: 40px; 
    width: 30%;
    background-color: #37b853;
    color: #fff;
    border-radius: 3px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }