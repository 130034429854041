.form-group {
    position: relative;
  }
  
  .suggestions-list {
    position: absolute;
    z-index: 1000;
    background: white;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  .suggestions-list li {
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .suggestions-list li:hover {
    background-color: #f2f2f2;
  }
  