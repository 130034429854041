/* Toggle.css */
.toggle-button {
    width: 60px;
    height: 30px;
    background-color: #ccc;
    border-radius: 15px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .toggle-button.active {
    background-color: #4CAF50;
  }
  
  .toggle-circle {
    position: absolute;
    top: 50%;
    left: 5px;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    transform: translateY(-50%);
    transition: left 0.3s ease;
  }
  
  .toggle-button.active .toggle-circle {
    left: 35px;
  }
  
  .Paragraph-1 {
    margin-top: 4px;
    font-size: 14px;
    margin-left: -35px;
    font-weight: bold; 
  }
  