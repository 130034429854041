@media print {
    body * {
      visibility: hidden;
    }
    .react-table,
    .react-table * {
      visibility: visible;
    }
    .react-table {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  