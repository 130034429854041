.FormRegister {
    display: flex;
    flex-direction: column;
    width: 30%;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-family: sans-serif;
  }
  
  .FormRegister label {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .FormRegister label input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .FormRegister button {
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .FormRegister button:hover {
    background-color: #3e8e41;
    cursor: pointer;
  }
  