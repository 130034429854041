.sidebar {
    position: fixed;
    top: 7%;
    left: -300px; 
    height: 100%;
    width: 220px; 
    overflow: hidden;
    transition: 0.5s;
    background-color: white;
    padding: 0; 
    z-index: 8887;
    border-right: 1px solid #dfdede;


    user-select: none;
    -webkit-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none;
}

.sidebar.show {
    left: 0;
}
  
.side-bar {
    height: 100%;
}
  
.side-bar p {
    margin-bottom: 1em;
    font-size: 1.2em;
}

.financeiro-items,
.cadastro-items {
    list-style: none;
    padding: 0;
    margin: 0;
}

.financeiro-items li,
.cadastro-items li {
    cursor: pointer;
    padding: 10px 0;
    
    width: 100%; 
}

.financeiro-items li:hover,
.cadastro-items li:hover {
    color: rgb(20, 20, 20);
}

.financeiro-items .submenu,
.cadastro-items .submenu {
    padding: 10px 0 ; 
    
    width: 100%; 
    padding-left: 0; 
}


.submenu{
    list-style: none;
}

.nested-list {
    padding-left: 0;
    background-color: #f0efef;
}


.submenu {
    list-style: none;
   
}

.submenu-text {
    padding-left: 4.0rem; 
}


.highmenu > li {
    padding-left: 2.5rem !important; 
}

.rotate-0 {
    transform: rotate(0deg);
    transition: transform 0.3s ease-in-out;
  }
  
  .rotate-90 {
    transform: rotate(90deg);
    transition: transform 0.3s ease-in-out;
  }
  
