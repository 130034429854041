.reactAccordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    background-color: #f5f5f5;
    cursor: pointer;
    height: 50px;
    transition: background-color 0.35s;
}

.reactAccordion-header:hover {
    background-color: #ddd;
}

.reactAccordion-body {
    padding: 1em;
    border-top: 1px solid #ccc;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: all .35s;
}

.reactAccordion-body.open {
    max-height: 500px;
}

  