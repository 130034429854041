.position-relative {
    position: relative;
  }
  
  .progress {
    overflow: visible; 
  }
  
  .progress-icon {
    position: absolute;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: #fff;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.5s, color 0.5s;

    
  }
  
  .icon-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .progress-icon-label-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .icon-label {
  font-size: 12px;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: -20px;
}

  
.reached {
  background-color: green !important;
  color: white !important;
}

.reached .icon-label {
  color: black !important;
}

  