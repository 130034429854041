#loginFormal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
   /* height: 95vh;
  overflow: hidden; /
  / background-color: blue;  abc*/
}


.formalTitle {
  font-size: 1.78rem;
  margin-bottom: 2rem;
  color: #3881ca;
  height: 50px; 
  width: 200px; 
}

.errorContainer {
  position: absolute;
  color: red;
  font-size: '0.85rem';
  bottom: 0;
  left: 0;
}


.registerTitle {
  font-size: 1.65rem;
  margin-bottom: 2rem;
  color: #3881ca;
  height: 50px; 
  width: 200px; 
}

.formFormal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #ffffff;
  
 
  width: 100%;
  max-width: 600px;
  height: 300px; 
}



.formalField {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
}

.formalLabel {
  font-size: 1.0rem;
  margin-bottom: 0.5rem;
  color: #0455b1;
  font-weight: bold;
}

.formalInput {
  padding: 0.5rem;
  font-size: 1.6rem;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  outline: none;
  transition: border 0.3s;
  width: 110%;
}

.formalInput:focus {
  border: 1px solid #2c3e50;
}

.formalActions {
  display: flex;
  justify-content: center;
}

#btnSubmit {
  margin-top: 1rem;
  padding: 0.4rem 1.3rem;
  font-size: 1.2rem;
  height: 40px; 
  width: 180%;
  background-color: #37b853;
  color: #fff;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

#btnSubmit:hover {
  background-color: #217cd8;
}


.page-container {
  display: flex;
  height: 98vh;
}

.blue-section {
  flex: 0.78;
  background-color: blue;
}

.clickableText {
  cursor: pointer;
}

.clickableText:hover {
  color: blue; 
  font-weight: 300;
}


.green-section {
  flex: 0.22;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  justify-content: flex-start;
  padding-top: 6%;
  align-items: center;
  border-left: 1px solid rgb(36, 160, 243);
}

.belowPassword{
  justify-content: flex-start;
  flex-direction:row-reverse;
}


@media only screen and (max-width: 1023px) {
  .page-container {
    flex-direction: column;
  }

  .blue-section {
    display: none;
  }

  .green-section {
    flex: 1;
  }
}

.selectEmpresa {
  width: 110%;
  margin-right: -10%;
  padding: 0.5rem;
  font-size: 1.1rem;
  height: 40px;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  outline: none;
  transition: border 0.3s;
  box-sizing: border-box;
}


.registerActions {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-left: 5%;
  
}


#btnCancel, #btnRegister {
  margin-top: 1rem;
  padding: 0.4rem 1.3rem;
  font-size: 1.2rem;
  height: 40px; 
  width: 45%; 
  color: #fff;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

#btnRegister {
  background-color: #37b853;
}

#btnRegister:hover {
  background-color: #217cd8;
}



#btnCancel {
  background-color: #b85337;
}

#btnCancel:hover {
  background-color: #d82137;
}



.lds-ring {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 3px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}